import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: resolve => require(['../view/index'],resolve),
    },
    {
      path: '/admin',
      name: 'login',
      component: resolve => require(['../view/login'],resolve),
    },
    {
      path: '/login-phone',
      name: 'loginPhone',
      component: resolve => require(['../view/login-phone'],resolve),
    },
    {
      path: '/sermonArticle',
      name: 'sermonArticle',
      component: resolve => require(['@/view/sermon/sermon-article'],resolve),
    },
    {
      path: '/live/common-live-streaming-viewing',
      name: 'CommonLiveStreamingViewing',
      component: resolve => require(['../view/live/common-live/common-live-streaming-viewing'],resolve),
    },
    {
      path: '/live/common-live-streaming-push-by-phone',
      name: 'commonLiveStreamingPushByPhone',
      component: resolve => require(['../view/live/common-live/common-live-streaming-push-by-phone'],resolve),
    },
    {
      path: '/interactive-live/interact',
      name: 'interact',
      component: resolve => require(['../view/live/interactive-live/interact'],resolve),
    },
    {
      path: '/live-playback',
      name: 'live-playback',
      component: resolve => require(['../view/live/interactive-live/live-playback'],resolve),

    },
    {
      path: '/navbar',
      name: 'navbar',
      component: resolve => require(['../view/navbar'],resolve),
      children: [
        {
          path: '/uploadResources',
          name: 'uploadResources',
          component: resolve => require(['../view/resources/uploadResources'],resolve),
        },
        {
          path: '/sermon/sermonOperate',
          name: 'sermonOperate',
          component: resolve => require(['../view/sermon/sermon-operate'],resolve),
        },
        {
          path: '/resources/resourceList',
          name: 'resourceList',
          component: resolve => require(['../view/resources/resourceList'],resolve),
        },
        {
          path: '/sermon/sermonList',
          name: 'sermonList',
          component: resolve => require(['../view/sermon/sermonList'],resolve),
        },
        {
          path: '/live/liveConfiguration',
          name: 'liveConfiguration',
          component: resolve => require(['../view/live/common-live/liveConfiguration'],resolve),
        },
        {
          path: '/live/common-live-streaming-push',
          name: 'commonLiveStreamingPush',
          component: resolve => require(['../view/live/common-live/common-live-streaming-push'],resolve),
        },
        {
          path: '/live/interactive-live/configuration',
          name: 'configuration',
          component: resolve => require(['../view/live/interactive-live/configuration'],resolve),
        },
        {
          path: '/live/manager',
          name: 'liveManager',
          component: resolve => require(['../view/live/live-manager'],resolve),
        },
        {
          path: '/live/interactive-live-streaming',
          name: 'interactiveLiveStreaming',
          component: resolve => require(['../view/live/interactive-live/interactive-live-streaming'],resolve),
        },
        {
          path: '/lyric/list',
          name: 'lyricList',
          component: resolve => require(['../view/lyric/index'], resolve),
        },
        {
          path: '/lyric/add',
          name: 'lyricAdd',
          component: resolve => require(['../view/lyric/add-lyric'],resolve)
        },
        {
          path: '/lyric/player',
          name: 'lyricPlayer',
          component: resolve => require(['../view/lyric/lyric-player'],resolve)
        },
        {
          path: '/church/index',
          name: 'churchIndex',
          component: resolve => require(['../view/church/index'],resolve)
        },
        {
          path: '/church/user',
          name: 'churchUser',
          component: resolve => require(['../view/church/user'],resolve)
        },
      ]
    },
  ]
})
