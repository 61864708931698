import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/icon/iconfont.css";
import "@/style/index.css";
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false

Vue.use(ElementUI);
// Vue.use(Antd);

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
// new Vue({
//   render: h => h(App),
// }).$mount('#app')

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')